import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isNull, isUndefined } from 'lodash';
import { Typography, withStyles } from '@material-ui/core';
import personLogo from '../../../../images/viinimaa/icon-for-how-many.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 4, 1, 0),
  },
  svg: {},
  numberOfPeople: { paddingLeft: theme.spacing(1.5) },
});

function PreparationNumberOfPeople({ classes, className, data, ...otherProps }) {
  if (isNull(data) || isUndefined(data)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)} {...otherProps}>
      <img src={personLogo} alt="logo" width="auto" height="35" className={classes.svg} loading="lazy" />
      <Typography variant="body2" className={classes.numberOfPeople}>
        {`${data} `}
      </Typography>
    </div>
  );
}

PreparationNumberOfPeople.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PreparationNumberOfPeople.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(PreparationNumberOfPeople);
