import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import CommonEmbedContainer from './common';

function EmbedContainer(props) {
  if (isEqual(siteId, siteIds.Viinimaa) || isEqual(siteId, siteIds.Folkofolk)) {
    return <CommonEmbedContainer {...props} />;
  }

  return null;
}

export default EmbedContainer;
