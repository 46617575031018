import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(2, 0),
    '@media print': {
      display: 'none',
    },
  },
});

function EmbedContainer({ classes, className, id, pageData }) {
  const contentType = get(pageData, ['internal', 'type']);
  const contentId = get(pageData, 'id');

  return (
    <div className={clsx(classes.root, className)}>
      <div id={id || contentId} className={`embed-container-${contentType} embed-container-${contentId}`} />
    </div>
  );
}

EmbedContainer.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

EmbedContainer.defaultProps = {
  classes: {},
  className: null,
  id: '',
  pageData: null,
};

export default withStyles(styles)(EmbedContainer);
