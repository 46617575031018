import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import '../../Content';

import RichText from '../../../shared/RichText';

const styles = () => ({
  root: {
    display: 'block',
  },
});

function PreparationSections({ classes, className, pageData }) {
  const inspectorProps = useContentfulInspectorMode();
  const preparationSections = get(pageData, 'preparationSections');

  return (
    <Container
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(pageData, 'contentful_id'),
        fieldId: 'preparationSections',
      })}>
      <RichText textData={preparationSections} />
    </Container>
  );
}

PreparationSections.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PreparationSections.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(PreparationSections);

export const query = graphql`
  fragment PreparationSectionsFragment on ContentfulRichText {
    raw
    references {
      ... on Node {
        ...ContentBrandFragment
        ...ContentCategoryFragment
        ...ContentAdvancedPageFragment
        ...ContentArticlePageFragment
        ...ContentLandingPageFragment
        ...ContentRecipePageFragment
        ...ContentAssetFragment
        ...ContentPreparationSectionFragment
        ...ContentPreparationStepFragment
        ...ContentContentListModuleFragment
        ...ContentButtonLinkModuleFragment
        ...ContentContactPersonModuleFragment
        ...ContentLatestArticlesModuleFragment
        ...ContentHeroImageModuleFragment
        ...ContentLeadTextModuleFragment
        ...ContentLinkBlockModuleFragment
        ...ContentAccordionModuleFragment
        ...ContentImageModuleFragment
        ...ContentMediaGalleryModuleFragment
        ...ContentFormModuleFragment
        ...ContentTableModuleFragment
        ...ContentVideoModuleFragment
        ...ContentFeaturedProductModuleFragment
        ...ContentProductSliderModuleFragment
      }
    }
  }
`;
