import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { Container, withStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import LatestArticlesModule from '../../../module/LatestArticlesModule';

const styles = theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(4, 0),
    '@media print': {
      display: 'none',
    },
  },
  relatedArticlePages: {},
});

function LatestArticles({ classes, className, pageData }) {
  const inspectorProps = useContentfulInspectorMode();
  const relatedArticlePages = get(pageData, 'relatedArticlePages');
  return (
    <div className={clsx(classes.root, className)}>
      <Container
        disableGutters
        {...inspectorProps({
          entryId: get(pageData, 'contentful_id'),
          fieldId: 'relatedArticlePages',
          locale: 'en-US',
        })}>
        <LatestArticlesModule data={relatedArticlePages} className={classes.relatedArticlePages} />
      </Container>
    </div>
  );
}

LatestArticles.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

LatestArticles.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(LatestArticles);

export const query = graphql`
  fragment LatestArticlesFragment on ContentfulLatestArticlesModule {
    id
    title
    contentType
    listAppearance
    backgroundImage {
      cloudinaryImage {
        secure_url
        public_id
      }
    }
    internal {
      type
    }
    articlePages {
      id
      fullPath
      publishingDate
      title
      shortDescription
      categories {
        id
        title
        fullPath
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            secure_url
            public_id
            raw_transformation
          }
        }
      }
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          secure_url
          public_id
          raw_transformation
        }
      }
    }
    brandPages {
      id
      fullPath
      title
      shortDescription
      categories {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            secure_url
            public_id
            raw_transformation
          }
        }
      }
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          secure_url
          public_id
          raw_transformation
        }
      }
    }
    categoryPages {
      id
      fullPath
      title
      shortDescription
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      parentCategory {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
    recipePages {
      id
      fullPath
      title
      shortDescription
      publishingDate
      heroImage {
        imageTitleText
        imageAltText
        cloudinaryImage {
          public_id
        }
      }
      categories {
        id
        title
        heroImage {
          imageTitleText
          imageAltText
          cloudinaryImage {
            public_id
          }
        }
      }
    }
  }
`;
