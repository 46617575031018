import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isNull, isUndefined } from 'lodash';
import { withStyles, Typography } from '@material-ui/core';
import AlcoholicityIcon from '../../../../images/viinimaa/alcohole.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 4, 1, 0),
  },
  alcoholicity: {},
});

function Alcoholicity({ classes, className, data, ...otherProps }) {
  if (isNull(data) || isUndefined(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)} {...otherProps}>
      <img src={AlcoholicityIcon} alt="logo" width="auto" height="35" className={classes.image} loading="lazy" />
      <Typography variant="body2">{`${data} %`}</Typography>
    </div>
  );
}

Alcoholicity.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Alcoholicity.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(Alcoholicity);
